<template>
  <templateComponent>
    <div class="faq">
      <div class="faq-title">
        <img src="@/assets/images/faq.png" alt="">
        <span>{{ $t("FAQ") }}</span>
      </div>
      <el-select @change="change" v-model="searchValue" @visible-change="visibleChange" filterable
        :placeholder="$t('输入关键字')" class="faq-search" clearable popper-class="faq-search-pop" :reserve-keyword="true"
        :popper-append-to-body="false">
        <div v-for="item in list" :key="item.id">
          <el-option v-for="news in item.news_list" :label="news.news_title" :key="news.id" :value="news.id">
            <div>
              <div class="faq-select-title">{{ news.news_title }}</div>
              <div class="faq-select-subtitle">{{ item.name }}</div>
            </div>
          </el-option>
        </div>

        <i class="el-icon-search el-icon" slot="prefix"></i>
      </el-select>
      <el-collapse v-model="active" accordion>
        <div v-for="item in list" :key="item.id" class="faq-quesion-item">
          <div class="faq-subtitle">{{ item.name }}</div>
          <el-collapse-item :title="news.news_title" :name="news.id" v-for="news in item.news_list" :key="news.id"
            :ref="`news${news.id}`">
            <div class="html" v-html="news.news_content"></div>
          </el-collapse-item>
        </div>
      </el-collapse>
    </div>
  </templateComponent>
</template>

<script>
import templateComponent from '@/components/template.vue';
import { news_type_list_api } from "@/api/news"
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      searchValue: '',
      active: "",
      list: []
    }
  },
  components: { templateComponent },
  methods: {
    change() {
      const oldContentClientRect = this.$refs[`news${this.active}`]?.[0].$el.querySelector(".el-collapse-item__content").getBoundingClientRect()
      this.active = this.searchValue
      this.$nextTick(()=>{
        let top =  this.$refs[`news${this.active}`][0].$el.getBoundingClientRect().top
        if(oldContentClientRect?.top < top){
          top -= oldContentClientRect.height
        }
        window.scrollBy({
          top: top - 70 ,
          behavior: 'smooth'
        });
      })
      
    },
    visibleChange(v) {
      if (v) this.searchValue = ""
    },
    async getList() {
      const { data: res } = await news_type_list_api({ page: 1, limit: 100 })
      if (res.code === 1) {
        this.list = res.data
      }
    }
  },
  async mounted() {
    const loadingCtx = Loading.service();
    await this.getList()
    loadingCtx.close()
  }
}
</script>

<style scoped lang="less" src="@/assets/css/faq.less"></style>